@import '../../../global/scss/config';

.client-settings {
  @include pad-box(24px);

  .client-outreach {
    &-header {
      @include border(none none solid none);
      margin-bottom: 24px;
      padding-bottom:6px;
      &-title {
        @include font(24px, 700, 0, $dark-gray);
        margin-bottom:6px;
      }
      &-description{
        @include font(14px, 500, 0, $medium-gray);
      }
    }
    &-buttons {
      @include flex(row,flex-start, flex-start);

      .test-button {
        margin-right: 24px;
      }
    }
  }

  .client-settings {
    &-header {
      @include border(none none solid none);
      margin-bottom: 24px;
      padding-bottom:6px;
      &-title {
        @include font(24px, 700, 0, $dark-gray);
        margin-bottom:6px;
      }
      &-description{
        @include font(14px, 500, 0, $medium-gray);
      }
    }
    &-select{
      width: 35% !important;
    }
  }

}