@import '../../../global/scss/config';

.base-framework-select {
  &-add-base-framework {
    @include flex(row, flex-start, center);
    @include pad-box(12px);
    width:100%;
  }
}

.base-framework-form {
  @include flex(row, flex-start, center);
  width:100%;
  flex-direction: row;
  &-input {
    flex: 1 1 50%;
    margin-bottom:0;
  }
}