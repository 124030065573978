@import "../../../global/scss/config";

.full-import {
  @include pad-box(24px);
  width:100%;
}

.job-table {
  &-content {
    .job-controls {
      @include flex(row, flex-start, center);
      .job-button {
        margin-right: 24px;
      }
    }
  }
}
