@import "../../../global/scss/config";

.form-drawer {
  & .form {
    width:100%;
    &.as-drawer {
      @include pad-box(24px);
    }
  }
}

.assessment-form-wrapper {
  @include pad-box(24px);
}