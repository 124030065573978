@import "../../../global/scss/config";

.app-sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  border: none;
  background: $secondary-background-color;
  @include border(none solid none none);

  &-popover {
    &-title {
      @include flex(row, space-between, center);
      width: 100%;

      .vendor {
        @include font(14px, 500, 0, $dark-gray);
      }
    }
    &-content {
      .user-info-container {
        @include flex(row, flex-start, flex-start);
        .user-info {
          align-self: center;
          .name {
            @include font(24px, 700, 0, $dark-gray);
            font-family: Inter, sans-serif;
          }
        }
      }

      .policies {
        @include flex(row, space-between, center);
        width: 100%;
        margin-top: 12px;
      }
    }
  }

  .ant-layout-sider-children {
    @include flex(column, flex-start, flex-start);
  }

  .notifications-container {
    margin-top: auto;
    width: 100%;
    @include pad-box(0 24px);
  }

  .navbar-footer {
    @include pad-box(0px 0px 12px 12px);
    font-size: 12px;
  }

  .user-container {
    @include pad-box(24px);
    width: 100%;

    &.collapsed {
      @include flex-center(row);
      @include pad-box(24px 12px);
    }
    // margin-top: auto;

    &:hover {
      cursor: pointer;
    }

    .ant-avatar {
      flex: 0 0 40px;
      //margin-right:12px;
    }

    .user {
      @include font(15px, 500, 0, white);
      border-radius: 5px;
      transition: all 0.2s;
      width: 100%;

      & > span {
        margin-left: 0;
      }

      .name {
        @include font(14px, 600, 0, black);
        margin-bottom: 3px;
        line-height: 1em;
        transition: all 0.2s;
        @media screen and (max-width: $screen-md-min) {
          display: none;
          @include pad-box(14px 0);
        }
      }

      .email {
        @include font(13px, 500, 0, $medium-gray);
        line-height: 1em;
      }
    }
  }

  &-menu {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    background: $secondary-background-color;

    .sidebar-group {
      @include flex(column, flex-start, flex-start);
      @include border(solid none none none);
      @include pad-box(12px 0);

      width: 100%;

      .group-header {
        @include pad-box(12px 24px);
        @include font(11px, 600, 0.05em, #6373d2);
        text-transform: uppercase;
      }

      &-items {
        @include flex(column, flex-start, flex-start);
        @include pad-box(0 12px);
        width: 100%;

        .sidebar-item {
          @include flex(row, flex-start, center);
          @include pad-box(9px 12px);
          @include border(solid);
          border-color: transparent;
          font-family: Inter, sans-serif;
          flex: 1 1 100%;
          color: #606791;
          border-radius: 5px;
          width: 100%;
          margin-bottom: 9px;
          transition: all 0.2s;

          &.collapsed {
            justify-content: center;
          }

          &-title {
            margin-left: 9px;
          }

          svg {
            fill: $light-blue;
            width: 24px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          &:not(.selected):hover {
            background: #efefef;
            cursor: pointer;
          }

          & > span {
            margin-right: 9px;
            font-size: 20px;
          }

          &.selected {
            background: white;
            border-color: $primary-border-color;
          }
        }
      }
    }
  }

  .white-logo {
    @include pad-box(14px 24px);
    @include flex(row, space-between, center);
    width: 100%;

    .toggle-collapse {
      transition: all 0.2s;

      &.collapsed {
        transform: rotate(180deg);

        & span {
          margin-left: 0;
        }

        & svg {
          margin-right: 0;
        }
      }
    }

    .st0 {
      fill: $orange !important;
    }

    & > img {
      width: 50px;
    }

    & > span {
      @include font(14px, 700, 0, $dark-gray);
      line-height: 1em;
      margin-left: 9px;
    }

    & svg {
      height: 52px;
      margin-right: 12px;
    }
  }
}
