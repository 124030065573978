@import "../../../global/scss/config";


.data-sync-audit-table {
  
  &-header {
    @include flex(row, space-between, center);

    .title {
      @include font(24px, 600, 0, #505050);
    }
  }
  .description {
    @include font(14px, 500, 0, #7f84a2);
    margin-bottom:12px;
  }
}

.azure-query {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 10px;
  margin-bottom: 16px;
}

.send-data-sync-message-drawer-button {
  margin-right: 24px;
}

.send-data-sync-message-drawer {
  .description {
    @include pad-box(24px);
    padding-bottom: 6px;
  }

  .content {
    @include pad-box(24px);
  }
}