@import '../../../global/scss/config';

.irn-select {
  &-add-irn {
    @include flex(row, flex-start, center);
    @include pad-box(12px);
  }
}

.irn-form {
  @include flex(row, flex-start, center);
  width: 100%;
  flex-direction: row;

  &-input {
    flex: 1 1 50%;
    margin-bottom: 0;
  }
}