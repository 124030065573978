@import "../../../global/scss/config";

.question-table {
  width:100%;

  &-header {
    @include flex(row, space-between, center);
    margin-bottom:24px;
    .title {
      @include font(24px, 600, 0, $dark-gray);
    }
  }

  .filters {

  }

  &-content {

  }

}