@import "../../../global/scss/config";

.status-as-tag {
  @include flex(row, center, center);
  @include pad-box(6px 12px);
  @include font(11px, 600, 0, $dark-gray);
  max-width:120px;
  line-height: 1em;
  background: gray;
  border-radius: 10px;

  .dot {
    min-width: 4px;
    min-height: 4px;
    background: black;
    margin-right: 6px;
    border-radius: 100%;
  }


  &.approved {
    color: $purple;
    background: #613D7C4D;

    .dot {
      background:$purple
    }
  }

  &.in_progress {
    color: $light-blue;
    background: #5D84E44D;

    .dot {
      background:$light-blue
    }
  }

  &.remediation_in_progress {
    color: $light-blue;
    background: #5D84E44D;

    .dot {
      background:$light-blue
    }
  }

  &.gap_validation_in_progress {
    color: $light-blue;
    background: #5D84E44D;

    .dot {
      background:$light-blue
    }
  }

  &.completed {
    color: $green;
    background: #16c7844D;

    .dot {
      background:$green
    }
  }

  &.submitted {
    color: $green;
    background: #16c7844D;

    .dot {
      background:$green
    }
  }

  &.remediation_submitted {
    color: $green;
    background: #16c7844D;

    .dot {
      background:$green
    }
  }

  &.gap_validation_submitted {
    color: $green;
    background: #16c7844D;

    .dot {
      background:$green
    }
  }

  &.pending_client_review {
    color: $orange;
    background: #E7872B4D;

    .dot {
      background:$orange
    }
  }

  &.open {
    color: $light-blue;
    background: #5D84E44D;

    .dot {
      background:$light-blue
    }
  }

  &.resolved {
    color: $green;
    background: #16c7844D;

    .dot {
      background:$green
    }
  }

  &.vendor_committed {
    color: $orange;
    background: #E7872B4D;

    .dot {
      background:$orange
    }
  }

  

}