@import "../../../global/scss/config";

.upload-modal {
  &-footer {
    @include flex(row, flex-end, center);

    padding: 8px;
  }

  .ant-modal-body {
    @include pad-box(24px !important);
  }

  .files {
    //@include pad-box(12px 24px);
    margin-top: 24px;
    //background-color: #eaeaea;
    border-radius: 3px;

    .file {
      @include flex(row, space-between, center);
      margin-bottom: 6px;

      &-name {
        @include font(14px, 500, 0, $light-blue);
      }
    }
  }

  .warning-text {
    color: $error-color;
  }
}
