@import '../../../global/scss/config';

.vendor-table {
  .ant-table-row {
    &:hover > td {
      cursor: pointer;
      background: transparent !important;
    }
  }


  .filters {
    @include flex(row, flex-start, center);
    margin-bottom: 12px;

    .search-bar {
      @include flex(row, flex-start, center);
      width:100%;
      margin-right:12px;

      .vendor-input {
        @include border(solid);
        @include font(14px, 600, 0, $medium-gray);
        @include border-and-box;

        .ant-input {
          border:none !important;
        }
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @include font(14px, 600, 0, $medium-gray)
      }

      ::-moz-placeholder { /* Firefox 19+ */
        @include font(14px, 600, 0, $medium-gray)
      }

      :-ms-input-placeholder { /* IE 10+ */
        @include font(14px, 600, 0, $medium-gray)
      }

      :-moz-placeholder { /* Firefox 18- */
        @include font(14px, 600, 0, $medium-gray)
      }

      & .ant-input-prefix {
        margin-right: 12px;
      }
    }
  }

}