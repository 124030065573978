@import "../../../global/scss/config";

.assessment-filters-form-wrapper {
  width:100%;
  .assessment-filters-form {
    width:100%;
    @include flex(row, flex-start, flex-end);

    & .ant-form-item {
      width:100%;
      margin-right:24px;
      margin-bottom:0;

      &:last-of-type {
        margin-right:0;
        width:initial;
      }
    }
  }
}