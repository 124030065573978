@import "../../../global/scss/config";

.invite-user-form {
  @include scale-max-content-width;
  @include flex(column, space-between, flex-start);
  @include max-size;

  &-content {
    @include pad-box(24px);
    width:100%;
  }

  .question-header {
    @include font(14px, 500, 0, $medium-gray);
  }
}

.invite-user-form-drawer {
  & .invite-user-form {
    width:100%;
    &.as-drawer {
      //@include pad-box(24px);
    }
  }
}