@import '../../../global/scss/config';

.question-select {
  &-irn {
    @include font(12px, 500, 0, $medium-gray);
    margin-right:6px;
  }

  &-option {
    @include flex(column, flex-start, flex-start);
    &-top{

    }
    &-bottom {
      @include flex(row, flex-start, center);
      @include font(13px, 500, 0, $medium-gray);
      .control-dependencies{
        @include flex(row, flex-start, center);
        .control-dependency {
          @include font(12px, 500, 0, white);
          @include pad-box(0 6px);
          border-radius:3px;
          background: $light-blue;
        }
      }
    }
  }

}