@import "../../../global/scss/config";

.import-job {
  width: 100%;

  &-header {
    @include font(20px, 700, 0, $dark-gray);
    @include border(none none solid none);
    @include pad-box(0 0 3px 0);
    margin-bottom: 18px;


  }

  .overview {
    .current-import-item-status {
      @include font(16px, 700, 0, $dark-gray);
      margin-bottom: 18px;
    }

    .single-import-job-item {
      @include font(16px, 700, 0, $dark-gray);
      margin-bottom: 18px;

      &-header {
        margin-bottom: 9px;

        & > span {
          margin-right: 6px;
        }

        &.error {
          color: $red;
        }

        &.validation {
          color: $orange;
        }
      }

      .errors {
        @include font(14px, 500, 0, $medium-gray);
        background: $secondary-background-color;
        border-radius: 5px;

        &-header {
          @include pad-box(12px 24px);
          @include font(14px, 600, 0, $light-blue);
          @include border(none none solid none);
        }

        &-content {
          @include pad-box(18px 24px);
        }

        .single-error {
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .validations {
        @include font(14px, 500, 0, $medium-gray);
        background: $secondary-background-color;
        border-radius: 5px;

        &-header {
          @include pad-box(12px 24px);
          @include font(14px, 600, 0, $light-blue);
          @include border(none none solid none);
        }

        &-content {
          @include pad-box(18px 24px);
        }

        .single-validation {
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}