@import "../../../global/scss/config";

.status {
  @include flex(row, flex-start, center);
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 14px;

  & > span {
    margin-right: 9px;

  }


}