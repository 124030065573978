@import "../../../global/scss/config";

.questionnaire {
  @include flex(column, flex-start, center);
  @include max-size;
  height: 100%;

  .sticky-header {
    @include flex(column, flex-start, flex-start);
    background: white;
    z-index: 1;
  }

  .completed-tab {
    color: $green;
  }

  .questionnaire-footer {
    align-self: flex-end;
    margin-top: auto;
    @include flex(row, flex-start, flex-start);
    position: fixed;
    bottom: 45px;
    right: 20px;

    .message-button {
      @include pad-box(12px 24px);

      &:hover {
        cursor: pointer;
      }
    }

    .saving-status {
      @include box-shadow-4;
      @include pad-box(12px 24px);
      @include border(solid);
      border-radius: 50px;
      margin-bottom: 24px;

      & > span {
        font-size: 16px;
      }

      &.success {
        color: $green;
        @include border(none);
        @include pad-box(0);
        box-shadow: none;
      }

      &.saving {
        color: $light-blue;

        & > span {
          margin-right: 9px;
        }
      }
    }
  }

  .stats {
    @include flex(column, flex-start, center);
    width: 100%;

    &-content {
      @include flex(column, flex-start, flex-start);
      width: 100%;

      &-top {
        @include pad-box(24px 24px 0);
      }

      &-bottom {
        @include flex(row, space-between, center);
        @include border(none none solid none);
        @include pad-box(12px 24px);
        width: 100%;

        .due-date {
          @include font(16px, 600, 0, black);
        }
      }
    }

    &-title {
      @include font(32px, 600, 0, black);
      line-height: 1em;
      margin-bottom: 0;
    }
  }

  .questions-answered {
    @include flex(row, flex-start, center);
    @include font(12px, 600, 0, $medium-gray);
    white-space: nowrap;

    .answered-length {
      margin-right: 12px;
    }
  }

  .buttons {
    @include flex(row, flex-end, center);

    .clear-dru {
      margin-right: 24px;
    }
  }

  .remediation-tab {
    @include flex(row, flex-start, flex-start);
    @include pad-box(24px);
    width: 100%;
    height: 100%;
  }

  .tab-pane {
    @include pad-box(24px);
    max-width: 800px;

    .tab-footer {
      @include flex(row, flex-end, center);
    }
  }

  &-control-list {
    @include flex(row, flex-start, flex-start);
    @include pad-box(24px);

    width: 100%;
    position: relative;

    &-content {
      @include flex(row, center, flex-start);
      max-width: 650px;
    }

    &-left {
      @include pad-box(24px);
      border-radius: 16px;
      background-color: #eef2f5;
      margin-right: 48px;
      flex: 0 0 35%;
      position: sticky;
      top: 24px;

      .category-container {
        margin-bottom: 24px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .category-title {
          @include font(24px, 600, 0, black);
          margin-bottom: 12px;
          line-height: 1em;

          &:hover {
            cursor: pointer;
            color: $orange;
            transition: all 0.2s;
          }

          &.selected {
            color: $orange;
          }
        }

        .controls {
          @include flex(column, flex-start, flex-start);

          .question-text {
          }
        }
      }
    }

    &-right {
      flex: 1 1 65%;

      &-content {
        @include flex(column, flex-start, center);
        @include scale-max-content-width;
      }

      .current-category {
        @include font(32px, 600, 0, black);
        @include border(none none solid none);
        @include pad-box(0 0 12px 0);
        line-height: 1em;
        margin-bottom: 24px;
      }

      .question-container {
        width: 100%;
      }
    }
  }

  .site-custom-tab-bar {
    @include pad-box(0 24px);
    z-index: 1;
    background: #fff;

    .ant-tabs-ink-bar {
      //height:2px;
    }
  }
}

.clear-dru-form-drawer-button {
  margin-right: 24px;
}

.clear-dru-form-drawer {
  .description {
    @include pad-box(24px);
    padding-bottom: 6px;
  }

  .content {
    @include pad-box(24px);
  }
}
