@import "../../../global/scss/config";

.template-question-builder {
  width:100%;
  .questions {
    margin-top:12px;
    .single-question{
      @include flex(row, flex-start, center);
      margin-bottom:6px;

      &:last-of-type {
        margin-bottom:0;
      }
      .irn {
        margin-right:9px;
      }
      .text {

      }
      .delete {
        margin-left:auto;
      }
    }
  }
}