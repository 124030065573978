@import "../../../../../global/scss/config";

.tasks-container {
  @include pad-box(16px);
  @include flex(column, flex-start, flex-start);
  @include max-size;

  border-radius: 8px;
  background-color: #e8f4f5;
  color: #54575a;

  &-header {
    margin-bottom: 8px;
  }

  &-description {
    margin-bottom: 16px;
  }

  &-tabs {
    border-radius: 8px;
    @include flex(row, flex-start, flex-start);
    width: 100%;

    .tasks-container-tab {
      border-radius: 8px;
      @include flex(row, center, center);
      width: 100%;
      padding: 0;

      > span {
        @include flex(row, center, center);
        width: 100%;

        .selected-tab {
          @include flex(row, center, center);
          color: white;
          background-color: #182a53;
          width: 100%; /* Add this line */
          box-sizing: border-box; /* Add this line */
        }

        .unselected-tab {
          @include flex(row, center, center);
        }
      }

      &-right {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      &-left {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  &-content {
    @include flex(column, flex-start, flex-start);
    background-color: white;
    border-radius: 8px;
    width: 100%;

    .sequential-tab {
      width: 100%;
      @include pad-box(12px);
    }

    .final-tab {
      width: 100%;
      @include pad-box(12px);
    }

    .tasks {
      margin-top: 16px;
    }

    .task {
      width: 100%;
      @include pad-box(8px);
      @include flex(column, flex-start, flex-start);

      &-header {
        width: 100%;
        @include flex(row, space-between, space-between);
      }

      &-response {
        width: 100%;
        @include flex(row, flex-start, flex-start);
        color: #145091;
      }
    }

    .selected-task {
      margin-top: 16px;
      border-radius: 8px;
      background-color: #e8f4f5;
    }

    .unselected-task {
      margin-top: 16px;
      border-radius: 8px;
      cursor: pointer;
    }

    .completed-task {
      margin-top: 16px;
      border-radius: 8px;
      background-color: #eef6f0;
    }

    .inadequate-task {
      margin-top: 16px;
      border-radius: 8px;
      background-color: #f5eeee;
    }
  }

  .task-container-form {
    @include flex(column, flex-start, flex-start);
    width: 100%;
    padding-top: 16px;
  }

  .task-attestation-form {
    @include flex(column, flex-start, center);
    width: 100%;
  }

  .task-comments-form {
    width: 100%;
    margin-bottom: 0;
  }

  .task-evidence-form {
    @include pad-box(8px);
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
    width: 100%;
    background-color: white;
  }
}
