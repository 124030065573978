@import "../../../../global/scss/config";

.assessment-remediation {
  @include flex(column, flex-start, flex-start);
  @include pad-box(0 24px 24px 24px);
  @include max-size;
  width: 100%;
  height: 100%;
  z-index: 0;

  .remediation-instructions {
    margin-bottom: 16px;
    @include font(14px, 500, 0, #7f84a2);
  }

  &-headers {
    width: 100%;
    @include flex(row, space-between, space-between);
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 8px;
    z-index: 10000;
    background-color: white;

    .title {
      @include font(20px, 700, 0, #505050);
    }
  }

  .risk-findings {
    margin-top: 24px;
    width: 100%;
  }
}

.default-loader-full {
  @include flex(column, center, flex-end);
  @include max-size;
}
