@import "../../../global/scss/config";

.notifications-wrapper {
    @include flex(row, flex-start, center);

    &.collapsed {
        @include flex(row, center, center);
    }

    &:hover {
        cursor:pointer;
    }
}

.popover-notifications {
    .ant-popover-inner-content {
        @include pad-box(0);
        .ant-card-body {
            @include pad-box(0);
        }
    }
}

.notifications-button {
    @include font(14px, 600, 0, black);
}