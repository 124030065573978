@import "../../../global/scss/config";

.template-form-wrapper {
  @include scale-max-content-width;
  @include flex(column, flex-start, flex-start);
  @include max-size;

  .question-select-header {
    @include font(24px, 600, 0, initial);
    @include pad-box(0 24px);
  }

  .question-table-select-wrapper {
    @include pad-box(24px);
    width:100%;
  }

  .template-import {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 50px;

    &-dragger {
      height: 150px;
      margin: 10px;
      padding: 10px;
    }
  }

  .template-form {
    @include scale-max-content-width;
    @include flex(column, space-between, flex-start);
    @include pad-box(24px);
  }
}


.template-form-drawer {
  & .template-form {
    width: 100%;

    &.as-drawer {
      @include pad-box(24px);
    }
  }
}
