@import "../../../global/scss/config";

.form-section-header {
  @include flex(column, flex-start, flex-start);




  .helper-text {

  }
  .top {
    @include flex(row, flex-start, row);
    @include pad-box(0 0 3px 0);
    @include border(none none solid none);
    width:100%;
    margin-bottom:9px;
  }
  .bottom {
    margin-bottom:12px;
    .help {
      @include font(14px, 500, 0, $medium-gray);
      font-style: italic;
    }
  }

  &-icon {
    margin-right:9px;
    color:$light-blue;
    font-size:18px;
  }
  &-text {
    @include font(18px, 700, 0, $dark-gray);
  }
}