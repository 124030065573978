@import '../../../global/scss/config';

.default-loader {
  @include flex(column, center, center);
  @include max-size;
  .loading-text {
    @include font(14px, 500, 0, $dark-gray);
    margin-top:12px;
    & span {
      margin-right:9px;
      color:$light-blue;
    }
  }
}