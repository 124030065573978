@import "../../../global/scss/config";

.assessment {
  @include max-size;
  @include flex(column, flex-start, flex-start);

  &-header {
    @include scale-max-content-width;
    @include pad-box(24px);

    .title {
      margin-bottom: 6px;

      .assessment-back {
        margin-right: 9px;
        color: $light-blue;

        &:hover {
          cursor: pointer;
        }
      }
      .assessment-title {
        margin-left: 9px;
      }
    }

    .assignments {
      @include flex(row, flex-start, center);
      @include font(12px, 600, 0, $medium-gray);

      .client {
        margin-right: 12px;
      }

      & span {
        font-size: 12px;
      }

      .vendor {
        margin-right: 12px;
        margin-left: 12px;
      }
    }
  }

  &-content {
    @include flex(column, flex-start, flex-start);
    width: 100%;

    .info {
      @include flex(row, space-between, flex-end);
      @include pad-box(0 24px 17px 24px);
      @include border(none none solid none);
      width: 100%;

      &-left {
        @include font(13px, 600, 0, $medium-gray);
        white-space: nowrap;
      }
    }

    .table {
      @include pad-box(24px);
      width: 100%;

      .header {
        @include flex(row, space-between, center);
        margin-bottom: 12px;

        .title {
          @include font(20px, 700, 0, $dark-gray);
        }

        .assessment-controls {
          @include flex(row, flex-end, center);

          .export-button {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
