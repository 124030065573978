@import "../../../global/scss/config";

.question-container {
  margin-bottom: 24px;
  padding-bottom: 12px;
  transition: all 0.3s;
  @include border(none none solid none);

  &.disabled {
    opacity: 1;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .question {
    @include flex(column, flex-start, flex-start);
    @include font(16px, 700, 0, black);
    margin-bottom: 12px;

    .key-control {
      @include flex(row, flex-start, center);
      @include font(14px, 500, 0, $medium-gray);
    }

    .approve-button {
      margin-top: 12px;
    }

    .question-header {
      width: 100%;
      @include flex(row, space-between, center);
      margin-bottom: 9px;

      .question-header-left {
        @include flex(row, flex-start, center);
      }

      .identifier {
        @include font(13px, 500, 0, white);
        @include pad-box(3px 6px);
        background: $orange;
        margin-right: 6px;
        border-radius: 3px;

        &.complete {
          background: $green;
        }
      }

      .icon {
        @include font(16px, 600, 0, $orange);
      }
    }

    .text {
      @include flex(row, flex-start, center);
      @include font(15px, 500, 0, $dark-gray);
    }
  }

  .clipboard-shortcut {
    @include font(14px, 600, 0, $medium-gray);

    &:hover {
      cursor: pointer;
    }

    .span {
      @include font(16px, 600, 0, $orange);
    }
  }

  .comments {
  }

  .skip-evidence {
    margin-bottom: 12px;
  }
}

.attachments {
  width: 100%;

  &-header {
    color: #8a8a8a !important;
    margin-bottom: 14px;
  }

  &-actions {
    @include flex(row, flex-start, center);
    .custom-button:first-of-type {
      margin-right: 12px;
    }
  }
  &-list {
    width: 100%;
    //margin-bottom:12px;
    .attachment {
      @include flex(row, space-between, center);
      @include pad-box(12px);
      border-radius: 8px;
      background-color: #eef2f5;

      &-left {
      }

      &-right {
        & > span {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
