@import '../../../global/scss/config';

.client-select {
  @include flex(column, flex-start, flex-start);

  &-name {
    @include font(14px, 600, 0, $dark-gray);
  }
  &-point-of-contact {
    @include font(13px, 500, 0, $medium-gray);
  }
}