/* Grays */

$dark-gray: #505050;
$medium-gray:#8a8a8a;
$light-gray:#bfbfbf;

/* Blues */

$light-blue:#5d84e4;
$light-blue-300: #cad9ff;
$dark-blue: #19315A;

/* Pinks */

$pink: #ff2d55;
$light-pink: #f17d93;

/* Oranges */

$red: #ff203c;

$orange:#e7872b;

$purple: #613d7c;

$green: #16c784;

$teal:#56B7B4;