@import '../../../global/scss/config';

.vendor-portfolio-container {
    @include max-size;

    &-header {
        @include flex(row, space-between, center);
        @include pad-box(23px 24px);
        @include border(none none solid none);
        @include font(23px, 700, 0, $dark-gray);
    }

    .vendor-portfolio-tabs {
        @include flex(row, flex-start, center);
        @include border(none none solid none);
        @include pad-box(12px 24px);

        .portfolio-tab {
            @include pad-box(6px 12px);
            @include font(13px, 600, 0, #44465b);
            @include border(solid);
            border-radius:5px;
            margin-right:9px;
            font-family:Inter, sans-serif;
            border-color: transparent;
            transition: all 0.2s;
      
            & > span {
              margin-right:6px;
              font-size: 16px;
            }
      
            &:not(.selected):hover {
              cursor: pointer;
              background:#f9f9fb;
            }
      
            &.selected{
              background:#f9f9fb;
      
              border-color: $primary-border-color;
            }
          }
    }

}