@import '../../../global/scss/config';

.vendor-files-table {
  width: 100%;
  
  .ant-table-row {
    &:hover > td {
      cursor: pointer;
      background: transparent !important;
    }
  }

  &-header {
    @include flex(row, space-between, center);
    @include pad-box(21px 24px);

    .title {
      @include font(24px, 700, 0, $dark-gray);
    }
  }


  .filters {
    @include pad-box(24px);

    .search-bar {
      @include flex(row, flex-start, center);

      .file-name-input {
        @include border(solid);
        @include font(14px, 600, 0, $medium-gray);
        @include border-and-box;

        .ant-input {
          border:none !important;
        }
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @include font(14px, 600, 0, $medium-gray)
      }

      ::-moz-placeholder { /* Firefox 19+ */
        @include font(14px, 600, 0, $medium-gray)
      }

      :-ms-input-placeholder { /* IE 10+ */
        @include font(14px, 600, 0, $medium-gray)
      }

      :-moz-placeholder { /* Firefox 18- */
        @include font(14px, 600, 0, $medium-gray)
      }

      & .ant-input-prefix {
        margin-right: 12px;
      }
    }
  }

  .vendor-files-table-content {
    @include pad-box(24px);

    .actions {
      @include flex(row, flex-end, center);
    
      .delete-file {
        margin-left: 12px;
      }
    }
  }
}