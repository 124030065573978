@import "../../../global/scss/config";

.notifications-overview {
    width: 100%;
  
    &-actions {
      @include flex(row, flex-start, center);
    }
  
    &-header {
      @include flex(row, space-between, center);
      @include pad-box(21px 24px);
  
      .title {
        @include font(24px, 700, 0, $dark-gray);
      }
    }

    &-content {
        @include pad-box(24px);
      }
    
}