@import "../../global/scss/config";

.questions-view {
  @include flex(column, flex-start, flex-start);
  @include pad-box(48px);
  @include max-size;

  &-content {
    @include scale-max-content-width;
  }
}