@import '../../../global/scss/config';

.client-user-management {
 @include pad-box(24px);

  .users {
    @include border(none none solid none);
    @include pad-box(0 0 24px 0);
    @include flex(row, space-between, flex-start);
    margin-bottom:24px;

    &:last-of-type{
      border:none;
    }
  }

  &-left {
    flex:0 1 30%;
    margin-right:24px;
    .header {
      @include font(18px, 700, 0, #14141d);
    }
    .description {
      @include font(14px, 500, 0, #7f84a2);
      margin-bottom:12px;
    }
  }
  &-right {
    flex:1 1 60%;
  }
}