@import "../../../global/scss/config";

.assessment-breadcrumb {
  @include font(14px, 700, 0, $dark-gray);
  @include flex(row, flex-start, center);

  .assessment-back {
    color: $light-blue;

    &:hover {
      cursor: pointer;
    }

  }

  .separator {
    margin-left:9px;
    font-size: 12px;
    color: $medium-gray;
  }

  .assessment-title {
    margin-left: 9px;

    &.has-questionnaire {
      color:$light-blue;
      &:hover{
        cursor: pointer;
      }
    }
  }

  .questionnaire-title {
    margin-left: 9px;
  }
}