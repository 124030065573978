@import '../../../global/scss/config';

.product-select {
  &-add-product {
    @include flex(row, flex-start, center);
    @include pad-box(12px);
    & button {
      flex: 1 0 200px;
    }
  }
}