@import "../../../global/scss/config";

.template-table {

  &-header {
    @include flex(row, space-between, center);
    margin-bottom: 24px;
    .template-buttons{
      display: flex;
      flex-direction: row;
      .custom-button {
        margin: 0 10px;
      }
    }
  }
  .title {
    @include font(14px, 700, 0, $dark-gray);
  }


}
