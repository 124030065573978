@import "../../global/scss/config";

.assessment-view {
  //@include pad-box(36px 48px);
  width:100%;
  height: 100%;
  .assessment-container{
    @include pad-box(0 !important);
  }

}