@import "../../../global/scss/config";


.questionnaire-table {

  .questionnaire {
    @include flex(row, flex-start, center);

    .typography{
      margin: 0;
      max-width:300px;

      &:hover {
        color:$light-blue;
        cursor:pointer;
      }
    }

  }
}