@import '../../../global/scss/config';

.custom-button {
  @include flex(row, center, center);
  @include pad-box(9px 18px);
  @include font(13px, 600, 0em, white);
  font-family: Inter, sans-serif;
  overflow:hidden;
  white-space: nowrap;
  text-align: center;
  background-color: $primary-button-bg-color;
  transition: all 0.2s;
  border-radius: 3px;
  outline: none;
  border:none;

  .icon-left {
    @include flex(row, flex-start, center);
    margin-right: 9px;
    opacity:0.6;

    span {
      font-size: 17px;
    }
  }

  .icon-right {
    @include flex(row, flex-start, center);
    margin-left: 9px;
    opacity:0.6;

    span {
      font-size: 14px;
    }
  }

  &.enabled {
    &.blue {
      &:hover {
        background-color: rgb(84, 164, 161);
      }
    }
    &.red {
      background-color: $red;
    }
    &.green {
      background-color: $green;
      svg {
        color:white;
      }
    }

    &.clear {
      background-color: transparent;
      @include font(13px, 700, 0, $dark-gray);

      &:hover {
        background-color: #ececec;
      }
    }
    &.corl-orange {
      background-color: #e7872b;
    }
    &.orange {
      //background-color: $error-color;
      @include box-shadow-5;
      border: 1px solid #a8abba;

      background-color: transparent;
      color: #3f4461;

      &:hover {
        background-color: #3f4461;
        color:white;
        border-color:transparent;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $medium-gray !important;
  }

  .dark-background {
    display: none;
  }

  .bar {
    display: none;
  }

  &.loading {
    height: 100%;
    position: relative;
    z-index: 0;
    background-color: $dark-gray !important;
    color:white !important;
    @keyframes loader-animation {
      0% {
        left: -100%;
      }
      49% {
        left: 100%;
      }
      50% {
        left: 100%;
      }
      100% {
        left: -100%;
      }
    }

    @keyframes background-animation {
      0% {
        top: -100%;
      }
      100% {
        top: 0;
      }
    }

    .dark-background {
      @include pad-box(12px);
      display: flex;
      top: 0;
      z-index: 1;
      position: absolute;
      background: $dark-blue;
      width: 100%;

      animation-name: background-animation;
      animation-duration: 0.2s;
      animation-timing-function: ease-out;

      .hidden-children {
        visibility: hidden;
      }
    }

    .bar {
      display: flex;
      z-index: 2;
      top: 0;
      width: 100%;
      position: absolute;
      min-height: 2px;
      background: rgb(1, 205, 254);
      background: linear-gradient(45deg, rgba(1, 205, 254, 1) 0%, rgba(185, 103, 255, 1) 100%);

      animation-name: loader-animation;
      animation-duration: 3s;
      animation-delay: 0.1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

}