@import "../../../../global/scss/config";

.risk-finding {
  @include flex(column, flex-start, flex-start);
  @include max-size;
  margin-bottom: 16px;

  .risk-finding-body-header {
    //@include flex(row, space-between, flex-start);
    width: 97%;

    .risk-reduction-badge {
      @include flex(row, flex-end, flex-start);
    }
  }

  .card-title {
    @include font(12px, 700, 0, $orange);
  }

  &-container {
    width: 100%;
    border-radius: 8px;
    background-color: #0000 !important;

    @include box-shadow-2;

    &:before {
      background: #0000 !important;
    }
  }

  &-body {
    @include flex(column, flex-start, flex-start);
    width: 100%;

    .risk-finding-tags {
      @include flex(row, flex-start, flex-start);
    }

    .risk-finding-control-question {
      @include flex(row, flex-start, flex-start);
      margin-bottom: 12px;
    }
  }

  .risk-finding-control-details {
    width: 100%;
    margin-top: 8px;
    background-color: $secondary-background-color !important;
    border: 1px solid $primary-border-color !important;
    border-radius: 8px;
    padding: 16px 16px 16px 20px !important;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      height: 100%;
      width: 5px;
      background: $secondary-background-color !important;
    }
  }
}

.risk-finding-drawer-container {
  width: 100%;
  margin-top: 16px;
  background-color: #faf5ed !important;
  border: 1px solid #ffe58f !important;
  border-radius: 8px;
  padding: 16px 16px 16px 20px !important;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 5px;
    background: #faf5ed !important;
  }

  &-content {
    @include flex(column, flex-start, flex-start);

    .register-hitrust-button {
      margin-top: 8px;
      margin-bottom: 8px;
      background-color: $dark-blue;
      color: white;
      //padding: 0;
      padding-right: 12px;
      padding-left: 12px;
      //margin: 0;

      &-icon {
        color: "white !important";
        fill: "white";
        font-size: 16px;
      }

      &-text {
        @include font(12px, 500, 0, white);
      }
    }
  }

  &-actions {
    @include flex(row, flex-start, flex-start);
    width: 100%;
    margin-top: 16px;
  }
}

.risk-findings-progress-bar {
  @include flex(row, flex-start, center);
  @include font(12px, 600, 0, $medium-gray);
  white-space: nowrap;
  margin-top: 12px;
  margin-bottom: 12px;

  .answered-length {
    width: 100%;
    margin-right: 12px;
  }
}

.risk-finding-form-drawer {
  height: 100%;

  .ant-tabs-content {
    height: 100%;
  }
}

.risk-finding-drawer-tabs {
  height: 100%;
  width: 100%;

  .ant-tabs-tabpane-active {
    height: 100%;
  }

  .risk-finding-remediation-tab {
    width: 100%;
    height: 100%;

    .risk-finding-remediation-container {
      @include flex(column, flex-start, flex-start);
      height: 100%;
      width: 100%;

      .risk-finding-remediation-top {
        @include pad-box(24px);
        width: 100%;

        .risk-finding-remediation-top-header {
          @include flex(row, space-between, flex-start);
          width: 95%;
        }
      }

      .risk-finding-remediation-bottom {
        width: 100%;
        height: 100%;
        background-color: $secondary-background-color;

        .risk-finding-remediation-response-container {
          width: 100%;
          height: 100%;

          .risk-finding-remediation-response-title {
            font-weight: 700;
            font-size: 24px;
          }
        }
      }

      .ant-tabs-top > div > .ant-tabs-nav {
        margin: 0px !important;
      }
    }

    .risk-finding-remediation-header {
      width: 100%;
      @include flex(row, space-between, flex-start);
      margin-top: 8px;
    }

    .risk-finding-remediation-response-container {
      @include flex(column, flex-start, flex-start);
      width: 100%;

      .risk-finding-remediation-response-top {
        @include flex(row, flex-start, flex-start);
        width: 100%;
        margin-top: 16px;
      }

      .risk-finding-remediation-response-field {
        @include flex(column, flex-start, flex-start);
      }
    }

    .risk-finding-remediation-details-container {
      @include flex(column, flex-start, flex-start);
      @include pad-box(24px);
      padding-top: 0;
      height: 100%;
      width: 100%;
      background-color: $secondary-background-color;

      .risk-finding-remediation-details-title {
        font-weight: 700;
        font-size: 24px;
      }

      .risk-finding-remediation-details-block {
        @include flex(column, flex-start, flex-start);
      }

      .risk-finding-remediation-details-form-title {
        @include font(18px, 700, 0, $orange);
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }
}

.risk-finding-history-tab {
  @include pad-box(24px);
  padding-top: 0;
}

.risk-finding-tag {
  border-radius: 6px;
}

.risk-finding-status-container {
  @include flex(row, flex-start, flex-start);
  width: 100%;

  .risk-finding-status-icon {
    @include flex(column, center, center);
    padding-right: 8px;
    padding-top: 4px;

    &-avatar {
      @include flex(column, center, center);
    }
  }

  .risk-finding-status-content {
    @include flex(column, flex-start, flex-start);

    .risk-finding-status-content-label {
      @include font(10px, 600, 0, $dark-gray);
    }

    .risk-finding-status-content-description {
      @include flex(row, flex-start, flex-start);
    }
  }
}

.site-custom-tab-bar {
  @include pad-box(0 24px);
  z-index: 1;
  background: #fff;

  .ant-tabs-ink-bar {
    //height:2px;
  }
}

.risk-finding-details-form-wrapper {
  width: 100%;
  margin-bottom: 100px;

  .risk-finding-details-form-checkboxes {
    @include flex(row, flex-start, flex-start);
  }

  .risk-finding-details-form-help {
    @include font(14px, 600, 0, $dark-gray);
    margin-bottom: 8px;
  }
}

.create-risk-finding-form-wrapper {
  @include pad-box(24px);
  padding-bottom: 0;
}

.risk-finding-remediation-response-tab {
  @include pad-box(24px);
  padding-top: 0;
  width: 100%;
  margin-bottom: 100px;

  .risk-finding-remediation-response-form-title {
    @include font(18px, 700, 0, $orange);
  }

  .risk-finding-response-form-wrapper {
    @include flex(column, flex-start, flex-start);
    width: 100%;

    .risk-finding-response-submit-button {
      margin-top: 32px;
      width: 100%;
      @include flex(row, flex-start, flex-start);
    }

    .key-control {
      @include flex(row, flex-start, center);
      @include font(14px, 500, 0, $medium-gray);
      margin-bottom: 16px;
    }

    .attachments {
      @include flex(column, flex-start, flex-start);
      width: 100%;

      .attachments-header {
        margin-bottom: 8px;
      }

      .attachments-actions {
        @include flex(row, flex-start, flex-start);
        width: 100%;
      }
    }
  }
}

.remediation-pathway-modal-title {
  font-weight: 700;
  font-size: large;
}

.remediation-pathway-header-title {
  font-weight: 700;
  font-size: medium;
  margin-bottom: 8px;
}

.remediation-pathway-header-description {
  margin-bottom: 16px;
  font-size: small;
}
