@import "../../../global/scss/config";

.notification {
    @include flex(column, flex-start, flex-start);
    padding: 18px 24px;
}
.unread-notification {
    @include flex(column, flex-start, flex-start);
    font-weight: 700;
    padding: 18px 24px;
}

.indicator {
    float: right;
    margin-left: 1rem;
}

.datetime {
    @include font(14px, 500, 0, $medium-gray);
    //margin-top:12px;
    font-size: 12px;
    font-weight: 500;
}

.notification-text {
    @include flex(row, flex-start, center);
    @include font(14px, 600, 0, $dark-gray);
    .ant-badge {
        margin-left:0;
    }
}
