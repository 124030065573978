@import "../../../../global/scss/config";

.risk-findings-table {
  width: 100%;

  &-header {
    @include flex(row, space-between, center);
    @include pad-box(21px 24px);

    .title {
      @include font(24px, 700, 0, $dark-gray);
    }
  }

  .expanded-questionnaire-table {
    @include box-shadow-4;
  }

  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background: transparent;
  }

  .ant-table-row {
    &:hover > td {
      //cursor: pointer;
      background: transparent !important;
    }

    .risk-reduction-badge {
      @include flex(row, flex-start, flex-start);
    }
  }

  .ant-table-cell {

    .risk-findings-name {
      @include flex(row, flex-start, center);
      @include font(14px, 700, 0, $dark-gray);

      .bottom {
        @include flex(column, flex-start, flex-start);
        .title {
          & > .anticon {
            margin-left: 6px;
            transition: all 0.2s;
          }

          &:hover {
            cursor: pointer;
            color: $light-blue;

            & > .anticon {
              margin-left: 9px;
            }
          }
        }

        .assignments {
          @include flex(row, flex-start, center);
          @include font(12px, 600, 0, #6c708b);

          .client {
            margin-right: 12px;
          }

          & span {
            font-size: 11px;
          }

          .vendor {
            margin-right: 12px;
            margin-left: 12px;
          }
        }
      }

      & > span {
        margin-right: 12px;
      }
    }
  }

  .filters {
    @include flex(row, flex-start, center);
    @include pad-box(24px);
    @include border(solid none);

    .search-bar {
      @include flex(row, flex-start, center);

      & input {
        @include font(14px, 600, 0, $medium-gray)
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @include font(14px, 600, 0, $medium-gray)
      }

      ::-moz-placeholder { /* Firefox 19+ */
        @include font(14px, 600, 0, $medium-gray)
      }

      :-ms-input-placeholder { /* IE 10+ */
        @include font(14px, 600, 0, $medium-gray)
      }

      :-moz-placeholder { /* Firefox 18- */
        @include font(14px, 600, 0, $medium-gray)
      }

      & .ant-input-prefix {
        margin-right: 12px;
      }
    }
  }

  &-content {
    @include pad-box(24px);
  }
}

.risk-finding-filters-form-wrapper {
  width:100%;
  .risk-finding-filters-form {
    width:100%;
    @include flex(row, flex-start, flex-end);

    & .ant-form-item {
      width:100%;
      margin-right:24px;
      margin-bottom:0;

      &:last-of-type {
        margin-right:0;
        width:initial;
      }
    }
  }
}