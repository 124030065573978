@import '../../../global/scss/config';

.user-table-delete-modal-content {
  @include pad-box(24px);
  @include font(14px, 500, 0, $dark-gray);
  & b {
    color:$orange;
  }
}

.actions {
  @include flex(row, flex-end, center);

  .delete-user {
    margin-left: 12px;
  }
}