@import "../../global/scss/config";

.notifications-view {
  width:100%;
  height: 100vh;

  .notifications-container{
    @include pad-box(0 !important);
    
  }

}