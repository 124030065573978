@import "../../global/scss/config";

.template-browser {
  @include pad-box(36px 48px);
  @include max-size;

  .assessment-container{
    @include pad-box(0 !important);
  }

}