@import "../../../../global/scss/config";

.risk-finding-history-container {
  @include flex(column, flex-start, flex-start);
  height: 100%;

  .risk-finding-history-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 8px;
  }

  .risk-finding-history-timeline {
    @include flex(column, flex-start, flex-start);
    width: 100%;

    .message {
        @include flex(row, center, flex-start);
        //@include pad-box(10px 5%);
        max-width: 100%;
        margin-bottom: 8px;
        padding-left: 6px;
        padding-right: 6px;

        .logo {
            height: 50px;
            width: 50px;
            margin-top: 8px;
        }
    
        .details {
            @include flex(column, flex-start, flex-start);
            width: 100%;
            margin-top: 4px;
            margin-bottom: 8px;
            margin-left: 12px;
    
            .author {
                //@include flex(row, center, center);
                @include font(14px, 500, 0, $medium-gray);
    
                margin-left: 10px;
            }
    
            .minimized-author {
                @include flex(row, center, center);
                @include font(12px, 700, 0, $dark-gray);
            }
    
            .timestamp {
                @include flex(row, center, center);
                @include font(14px, 500, 0, $medium-gray);
                margin-left: 10px;
            }
    
            .minimized-timestamp {
                @include flex(row, center, center);
                @include font(10px, 400, 0, $dark-gray);
                margin-left: 12px;
            }
        }
    
        .content {
            @include flex(column, flex-end, flex-end);
            margin-left: 12px;

            .text {
                @include flex(row, flex-start, flex-start);
                font-weight: 500;
                @include pad-box(8px 16px 8px 16px);
                border-radius: 5px;
                white-space: pre-line;
            }
        }
      }
  }
  
}
